import React from 'react';
import { Box, Flex, Heading, List, ListItem, Stack, Text } from '@chakra-ui/core';
import Layout from '../components/Layout';
import BackButton from '../components/BackButton';

const AboutCard = () => {
  return (
    <Box borderWidth="1px" rounded="lg" p={12}>
      <Heading as="h1" mb={6}>
        About Me
      </Heading>

      <Stack>
        <Text>
          I create web applications and love doing it. After graduating from the University of Toronto with a Computer
          Engineering degree, I've worked at several SaaS companies helping to build out their products. I have
          experience in both architecting systems from scratch and scaling existing systems.
        </Text>

        <br />

        <Text>Here are some of the tools I'm currently using:</Text>
        <List styleType="disc" ml={4}>
          <ListItem>Javascript / Typescript / React</ListItem>
          <ListItem>Ruby / Rails</ListItem>
          <ListItem>PostgreSQL</ListItem>
          <ListItem>Docker</ListItem>
          <ListItem>Terraform</ListItem>
        </List>

        <br />

        <Text>What I'm experimenting with:</Text>
        <List styleType="disc" ml={4}>
          <ListItem>React Native</ListItem>
          <ListItem>Serverless</ListItem>
        </List>
      </Stack>
    </Box>
  );
};

const AboutPage = () => {
  return (
    <Layout>
      <Flex as="section" flexDirection="column" justifyContent="center" minHeight="calc(100vh - 56px)">
        <Flex alignItems="center" mb={2}>
          <BackButton />
        </Flex>

        <AboutCard />
      </Flex>
    </Layout>
  );
};

export default AboutPage;
