import React from 'react';
import { Link as RouterLink } from 'gatsby';
import { Icon, Link } from '@chakra-ui/core';

const BackButton = () => {
  return (
    <Link as={RouterLink} to="/" display="flex" alignItems="center">
      <Icon name="chevron-left" fontSize="2xl" />
      Back
    </Link>
  );
};

export default BackButton;
